import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    secondsLeft: String,
    expiredLabel: String,
  };

  static targets = ["label", "timer"];

  static HOURS = 1000 * 60 * 60 * 24;
  static MINUTES = 1000 * 60 * 60;
  static SECONDS = 1000 * 60;

  connect() {
    this.openTime = new Date().getTime();
    this.update();
    this.timer = setInterval(() => {
      this.update();
    }, 1000);
  }

  disconnect() {
    this.stopTimer();
  }

  stopTimer() {
    if (this.timer) clearInterval(this.timer);
  }

  setFinishLabel() {
    this.timerTarget.innerHTML = this.expiredLabelValue;
    this.timerTarget.classList.add("c-countdown_bar-finished");
    this.labelTarget.style.display = "none";
  }

  update() {
    const difference = this.timeDifference();

    if (difference < 0) {
      if (this.expiredLabelValue) {
        this.setFinishLabel();
        this.stopTimer();
      }
      return;
    }

    const days = Math.floor(difference / this.constructor.HOURS);
    const hours = Math.floor((difference % this.constructor.HOURS) / this.constructor.MINUTES);
    const minutes = Math.floor((difference % (this.constructor.MINUTES)) / (this.constructor.SECONDS));
    const seconds = Math.floor((difference % this.constructor.SECONDS) / 1000);

    const daysElement = this.timerTarget.getElementsByClassName("days")[0];
    const hoursElement = this.timerTarget.getElementsByClassName("hours")[0];
    const minutesElement = this.timerTarget.getElementsByClassName("minutes")[0];
    const secondsElement = this.timerTarget.getElementsByClassName("seconds")[0];

    if (daysElement) daysElement.innerHTML = days;
    if (hoursElement) hoursElement.innerHTML = hours.toString().padStart(2, "0");
    if (minutesElement) minutesElement.innerHTML = minutes.toString().padStart(2, "0");
    if (secondsElement) secondsElement.innerHTML = seconds.toString().padStart(2, "0");
  }

  timeDifference() {
    const pastTime = new Date().getTime() - this.openTime;
    return this.secondsLeftValue * 1000 - pastTime;
  }
}
